<template>
  <div id="containerbar">
    <div class="rightbar">
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-4 col-lg-4">
            <h4 class="page-title">{{ $t(title) }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("warehouse.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#">{{ $t(title) }}</a>
                </li>
                <li class="breadcrumb-item">
                  {{ $t("warehouse.non_compliant_product") }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <template>
              <div class="card">
                <AlertMessageComponent ref="alertMessageComponent" />
                <v-card-title>
                  <div class="row">
                    <div class="col">
                      <h5 class="card-title mb-0">
                        <i class="feather icon-package mr-2"></i>
                        {{ $t("warehouse.non_compliant_product") }}
                      </h5>
                    </div>
                  </div>
                </v-card-title>
                <!-- Table de WareHouse -->
                <div class="row">
                  <div class="col-md-12">
                    <WarehouseTableComponent
                      :switchList="switchList"
                      :switchHeader="switchHeader"
                      :searchTableMain="searchTableMain"
                      :switchDetails="switchDetails"
                      :switchSoldOut="switchSoldOut"
                      :showAction="showAction"
                      :viewItem="viewItem"
                      :index="index"
                      :isDetail="
                        withPermissionName(permissions.WAR_INV_NCP_PRO_TRA_01)
                      "
                      :updateLot="false"
                      :stockReAsing="false"
                      :stockUpdateQuantify="false"
                    />
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="p-0">
        <v-toolbar dark color="blue-fmedical">
          <v-toolbar-title class="pt-4">
            <p class="text-center">
              <i class="ti-stats-up mr-2"></i>
              {{ $t("warehouse.product_tracking") }}
            </p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="container">
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title mt-5">
                <i class="fa fa-inbox mr-2"></i
                >{{ $t("warehouse.inventory_detail") }} #
                {{ stockDetail.idStock }}
              </h5>
              <hr />
              <div class="row">
                <div class="col-md-4 col-sm-12">
                  <strong>{{ $t("warehouse.sub_ref") }}</strong
                  ><br />
                  <i class="ti-package mr-2 text-fmedical-blue"></i
                  >{{ stockDetail.remmisionKey }}
                </div>
                <div class="col-md-4 col-sm-12">
                  <strong>{{ $t("warehouse.sub_data") }}</strong
                  ><br />
                  <i class="fa fa-calendar mr-2 text-fmedical-blue"></i
                  >{{ stockDetail.dateIn }}
                </div>
                <div class="col-md-4 col-sm-12">
                  <strong>{{ $t("warehouse.sub_data_remission") }}</strong
                  ><br />
                  <i class="fa fa-calendar mr-2 text-fmedical-blue"></i
                  >{{ stockDetail.dateInputRemmision }}
                </div>
              </div>
              <div class="row">
                <div class="col-md-2">
                  <strong>{{ $t("warehouse.sub_clave") }}</strong
                  ><br />
                  <i class="fa fa-file-o mr-2 text-fmedical-blue"></i>
                  {{ moveItemHolder.productKey }}
                </div>
                <div class="col-md-10" align="justify">
                  <strong>{{ $t("warehouse.sub_product") }}</strong
                  ><br />
                  <i class="fa fa-file-o mr-2 text-fmedical-blue"></i>
                  {{
                    moveItemHolder.productName +
                      " " +
                      moveItemHolder.productDescripcion
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-5">
            <template>
              <div class="col-12">
                <v-tabs
                  class="br"
                  v-model="tab"
                  background-color="#263A5B"
                  centered
                  dark
                  icons-and-text
                >
                  <v-tab href="#1">
                    {{ $t("warehouse.sub_detail_moven") }}
                    <v-icon>fa fa-info-circle</v-icon>
                  </v-tab>
                  <v-tab href="#2">
                    {{ $t("warehouse.sub_detail_stock_origin") }}
                    <v-icon>fa fa-folder-open-o</v-icon>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="1" eager>
                    <div
                      v-if="listFilterStatusDestinaction.length > 2"
                      class="col-md-12 col-sm-12 pb-0 pt-4"
                    >
                      <div class="row">
                        <div class="col-md-4 col-sm-12">
                          <v-select
                            v-model="filterStatusDestination"
                            dense
                            outlined
                            :items="listFilterStatusDestinaction"
                            menu-props="auto"
                            :label="$t('label.filter_status')"
                            hide-details
                          ></v-select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 col-sm-12 pt-0">
                      <StockDestinationList
                        ref="stockDestination"
                        :stockDestinationList="stockDestinationList"
                      />
                    </div>
                  </v-tab-item>
                  <v-tab-item value="2" eager>
                    <div class="col-md-12 col-sm-12">
                      <StockOriginList
                        ref="stockOrigin"
                        :stockOriginList="stockOriginList"
                      />
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </div>
            </template>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import WarehouseTableComponent from "@/components/warehouse/WarehouseTableComponent";
import StockDestinationList from "@/components/warehouse/StockDestinationList";
import StockOriginList from "@/components/warehouse/StockOriginList";
import ButtonPrintComponent from "@/common/table/ButtonPrintComponent";
import { UtilFront, PermissionConstants } from "@/core";
import {
  WarehouseRequest,
  ExcelRequest,
  StockRequest,
  ProductResquest,
  RemissionRequest,
} from "@/core/request";
import moment from "moment";
// Vuex implementación
import { mapState, mapMutations } from "vuex";
import {
  WAREHOUSE_NON_CONFORM,
  PRODUCT_STOCK,
  STOCK_BY_PRODUCT,
} from "@/core/DataTableHeadersLocale";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  data() {
    return {
      title: "warehouse.warehouse",
      search: "",
      mainStockList: [],
      objectResponse: {},
      currentItem: {},
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
      tab: 0,
      /* Cambios de Stock */
      mainProductStockHeader: PRODUCT_STOCK(),
      stockProductHeader: STOCK_BY_PRODUCT(),

      dialogQuantity: false,
      searchTableMain: "",
      searchTableProducts: "",
      searchStockProduct: "",
      mainProductStockList: [],
      stockProduct: [],
      currentIdProduct: 0,
      currentProduct: "",
      currentProductDescription: "",
      sending: false,
      date: "",
      dateModel: {
        startDate: "",
        endDate: "",
      },
      model: {},
      index: 0,
      dialog: false,
      dialogMovEx: false,
      dialogMove: false,
      dialogEdit: false,
      editedIndex: -1,
      newQuantity: 0,
      newLocation: "",
      temQuantity: 0,
      movebtn: true,
      moveItemHolder: {
        idStock: "",
        productName: "",
        locationKey: "",
        quantity: "",
      },
      updateStockQuantity: {
        idStock: "",
        quantity: 0,
        branchOfficeId: "",
        userId: "",
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      stockOriginList: [],
      stockDestinationList: [],
      stockOriginListFilter: [],
      stockDestinationListFilter: [],
      /* Filtros */
      listFilterStatusDestinaction: [],
      listFilterStatusOrigin: [],
      filterStatusDestination: "",

      stockDetail: [],
      branchOffice: {},
      branchOffices: [],
      action: 1,
      permissions: PermissionConstants,
      /*Cuando se envia*/
      sending: false,
      loading: null,

      /* Global */
      product: {},

      /* Detalle de Existencia (Cambio de stock) */
      mainStockHeader: WAREHOUSE_NON_CONFORM(),
      level: "",
      switchDetails: true,
      switchSoldOut: false,
      expired: false,
      today: moment().format('YYYY-MM-DD'),
    };
  },
  computed: {
    checkQuantity() {
      return `required|min_value:1|max_value:${parseInt(this.temQuantity) +
        parseInt(this.moveItemHolder.quantity)}`;
    },
    // Extración del state de auth para la sucursal global
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    switchHeader() {
      return this.switchDetails ? WAREHOUSE_NON_CONFORM() : PRODUCT_STOCK();
    },
    switchList() {
      return this.switchDetails
        ? this.mainStockList
        : this.mainProductStockList;
    },
  },
  methods: {
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    cancelMove() {
      this.dialogMove = false;
      this.$refs.obs1.reset();
      this.$refs.alertdialogMove.reloadComponent(0);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      if (action == 1) {
        this.$refs.observer.reset();
        this.model = {};
        this.action = action;
        this.dialogEdit = false;
      } else {
        this.action = action;
      }
    },
    async viewItem(item) {
      try {
        this.tab = 1;
        this.editedIndex = this.mainStockList.indexOf(item);
        this.moveItemHolder = Object.assign({}, item);
        this.loading = UtilFront.getLoadding();
        this.dialog = true;
        await this.getStockHistory(this.moveItemHolder.idStock);
        this.$refs.stockDestination.reloadComponent(this.stockDestinationList);
        this.$refs.stockOrigin.reloadComponent(this.stockOriginList);
      } catch (error) {
        this.$toast.error("Algo salio mal; Intenta de nuevo.");
        this.$refs.stockDestination.reloadComponent([]);
        this.$refs.stockOrigin.reloadComponent([]);
        this.dialog = false;
      } finally {
        this.loading.hide();
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async getStockHistory(idStock) {
      await WarehouseRequest.stockHistoryOrigin({
        id: idStock,
      })
        .then((response) => {
          this.stockOriginList = response.data.data.objects;
          this.stockOriginListFilter = response.data.data.objects;
          this.stockOriginListFilter.forEach((data) => {
            let listStatus = {
              value: data.statusNumber,
              text: data.status,
            };
            this.listFilterStatusOrigin.push(listStatus);
          });
          let hash = {};
          this.listFilterStatusOrigin = this.listFilterStatusOrigin.filter(
            (data) => (hash[data.value] ? false : (hash[data.value] = true))
          );
          this.listFilterStatusOrigin = [
            ...this.listFilterStatusOrigin,
            {
              value: "",
              text: "Sin filtro",
            },
          ];
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});

      await WarehouseRequest.stockHistoryDestination({
        id: idStock,
      })
        .then((response) => {
          this.stockDestinationList = response.data.data.objects;
          this.stockDestinationListFilter = response.data.data.objects;
          this.listFilterStatusDestinaction = [];
          this.stockDestinationListFilter.forEach((data) => {
            let listStatus = {
              value: data.statusNumber,
              text: data.status,
            };
            this.listFilterStatusDestinaction.push(listStatus);
          });
          let hash = {};
          this.listFilterStatusDestinaction = this.listFilterStatusDestinaction.filter(
            (data) => (hash[data.value] ? false : (hash[data.value] = true))
          );
          this.listFilterStatusDestinaction = [
            ...this.listFilterStatusDestinaction,
            {
              value: "",
              text: "Sin filtro",
            },
          ];
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
      await WarehouseRequest.stockDetail({
        id: idStock,
      })
        .then((response) => {
          this.stockDetail = response.data.data.object;
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
      this.filterStatusDestination = "";
    },
    async listAll() {
      await StockRequest.listAllNonConform({
        idBranchOffice: this.branchOffice.idBranchoffice,
      })
        .then((response) => {
          let values = response.data.data.objects;
          values.map( element => {
            let date = element.expirationDate;
            let parsed = moment(date, "DD/MM/YYYY");
            let valid = moment(this.today).isSameOrBefore(parsed) ? moment.duration(moment(parsed).diff(this.today))
                         :'Caducado';
                         
            element.validDate = valid;
           })
          this.mainStockList = response.data.data.objects;
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    async listBranchOfficeByUser() {
      try {
        this.branchOffice = this.branchOfficeState;
        this.updateAction(1);
        this.loading = UtilFront.getLoadding();
        await this.listAll();
        /*switch (this.$route.path) {
          case "/almacen/productonoconforme":
            await this.listAll();
            //await this.listAllProducts();
            break;
          case "/caducados":
            //await this.listAllExpired();
            //await this.listAllProductsExpired();
            break;
          default:
        }*/
        this.loading.hide();
      } catch (error) {
        this.$toast.error(error);
      } finally {
        this.loading.hide();
      }
    },

    edit(object) {
      this.dialogEdit = true;
      this.model = {
        ...this.model,
        ...object,
      };
      this.currentItem = object;
      this.model.fullName = `${this.model.productName} ${this.model.productDescripcion}`;
      //this.changeAction(3);
    },
  },
  async created() {
    //this.expired = this.$route.path === "/almacen/productonoconforme" ? false : true;
    await this.listBranchOfficeByUser();
  },
  watch: {
    async branchOfficeState() {
      await this.listBranchOfficeByUser();
    },
    "$i18n.locale": function() {
      this.mainStockHeader = STOCK();
      this.stockProductHeader = STOCK_BY_PRODUCT();
    },
    filterStatusDestination() {
      if (this.filterStatusDestination == "") {
        this.stockDestinationList = this.stockDestinationListFilter;
      } else {
        this.stockDestinationList = this.stockDestinationListFilter.filter(
          (element) => {
            return element.statusNumber == this.filterStatusDestination;
          }
        );
      }
      this.$refs.stockDestination.reloadComponent(this.stockDestinationList);
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    WarehouseTableComponent,
    StockDestinationList,
    StockOriginList,
    AlertMessageComponent,
    ButtonPrintComponent,
  },
};
</script>

<style scoped>
.blue-fmedical {
  background-color: #263a5b !important;
  color: white !important;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 10px;
}

.br {
  border-radius: 8px !important;
}
</style>
